html {
  scroll-behavior: smooth;
}

body {
  font-family: "Yantramanav", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  overflow-x: hidden;
}

.primary-light {
  color: $primary-light !important;
}

.no-selectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

section {
  margin-top: 3.125rem;
  padding: 0px 1.5rem;
  height: 100%;
  width: 100vw;
}

.container-dark {
  background-color: $primary-dark;
}

.common-container {
  width: 100vw;
  padding: 3rem 0;
}

.common-button {
  color: $primary;
  text-transform: uppercase;
  border: 2px solid $primary;
  background: transparent;
  border-radius: 8px;
  font-weight: 700;
  font-size: $button-fs;
  padding: 10px 49px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgba($primary, 0.2);
  }
}

#triangle-bottomright {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10vw 100vw;
  border-color: transparent transparent $primary-dark transparent;
}
#triangle-bottomleft {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 10vw 0;
  border-color: transparent transparent $primary-dark transparent;
}
#triangle-topleft {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10vw 100vw;
  border-color: transparent transparent transparent $primary-dark;
}
#triangle-topright {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 10vw 0;
  border-color: transparent $primary-dark transparent transparent;
}
#divider-topleft {
  position: absolute;
  top: -124px;
  left: -213px;
  transform: rotateY(185deg) rotate(5.7deg);
  &.mob-v {
    height: 70px;
    top: -66px;
    left: -213px;
  }
}
#divider-topright {
  position: absolute;
  top: -126px;
  right: -213px;
  transform: rotate(5.7deg);
  &.mob-v {
    height: 70px;
    top: -66px;
    left: -213px;
  }
}
#divider-bottomright {
  position: absolute;
  bottom: -128px;
  right: -193px;
  transform: rotateX(180deg) rotate(5.7deg);
  &.mob-v {
    height: 70px;
    bottom: -67px;
    left: 221px;
  }
}
#divider-bottomleft {
  position: absolute;
  bottom: -124px;
  left: -213px;
  transform: rotateY(180deg) rotateX(180deg) rotate(5.7deg);
  &.mob-v {
    height: 70px;
    bottom: -67px;
    left: 221px;
  }
}

// MOBILE
@media only screen and (max-width: 576px) {
  .full-size-section {
    height: 100vh;
  }

  .common-button {
    padding: 5px 25px;
  }
}
