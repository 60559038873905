.services-list {
  overflow: auto;
  white-space: nowrap;
  > div {
    display: inline-block;
  }
}

.service-section {
  margin: 75px 0px;
}

.service-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.125rem;
  max-width: 155px;
  transition: color 0.2s ease;
  overflow: hidden;
  > .icon {
    font-size: 2rem;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover:not(.active) {
    color: rgba($primary, 0.8) !important;
  }
  &.active {
    color: $primary;
  }
  &.inactive {
    color: $inactive-text-color;
  }

  @media only screen and (max-width: 576px) {
    font-size: 0.875rem;
    max-width: 140px;
    > .icon {
      font-size: 1.75rem;
    }
  }
}

.service-detail-row {
  img {
    max-height: 40vh;
    width: 100%;
    object-fit: contain;
  }
}

.service-img-mob {
  height: auto;
  max-height: 30vh;
  width: 100%;
  object-fit: contain;
}
