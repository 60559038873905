.desk-menu-container {
  width: 100vw;
  top: 0;
  position: fixed;
  background-color: white;
  z-index: 1000;
  &.shadow {
    box-shadow: 0px 4px 30px rgba($color: #000000, $alpha: 0.15);
  }
  .desk-menu {
    padding: 1.5rem 0rem;
    .title {
      font-size: 2.125rem;
      font-weight: 700;
      color: $primary-dark;
    }
    .voice-menu {
      padding: 0rem 1rem;
      text-transform: uppercase;
      font-size: 1.06rem;
      font-weight: 700;
      color: $primary-dark;
      transition: color 0.2s ease;
      &:hover {
        cursor: pointer;
        color: $primary;
      }
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.mob-menu {
  top: 0;
  width: 100vw;
  position: fixed;
  background-color: white;
  z-index: 1000;
  padding: 1.5rem 1.5rem;
  border-radius: 0px 0px 15px 15px;
  &.shadow {
    box-shadow: 0px 4px 30px rgba($color: #000000, $alpha: 0.15);
  }
  .title {
    font-size: 2.125rem;
    font-weight: 700;
    color: $primary-dark;
  }
  .burger {
    color: $primary-dark;
    fill: $primary-dark;
    &:hover {
      cursor: pointer;
    }
  }
  .menu-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    padding: 1.5rem 1.5rem 4.5rem;
    background-color: $primary-dark;
    -webkit-font-smoothing: antialiased;
    transform: translateX(+100%);
    transition: transform 0.3s ease-in;
    &.visible {
      transform: translateX(0);
    }
    &.not-visible {
      transform: translateX(100%);
    }
    img {
      position: absolute;
      top: 30%;
      left: 0px;
    }
    .heading {
      .title {
        font-size: 2.125rem;
        font-weight: 700;
        color: white;
      }
    }
    .close-icon {
      color: white;
      font-size: 1.5rem;
      &:hover {
        cursor: pointer;
      }
    }
    .voice-menu {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      font-size: 2.125rem;
      font-weight: 700;
      color: white;
      transition: color 0.2s ease;
      &:hover {
        cursor: pointer;
        color: $primary;
      }
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
