.process-container {
  // height: 100vh;
  // overflow: "hidden";

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    height: max-content;
  }
  @media only screen and (max-width: 768px) {
    height: 50vh;
  }
}

.clip-rect {
  clip-path: polygon(100% 20%, 100% 100%, 0 80%, 0 0);
}

.process-container-det {
  margin: 100px 0px;
  img {
    height: 100%;
    max-height: 500px;
    object-fit: contain;
  }
}

.navigation {
  color: $primary;
  font-size: 3rem;
  &.mobile {
    font-size: 2rem;
  }
}

.container-arrow {
  width: 100px;
  max-width: 100px;
  .section-short-title {
    font-size: 1.2rem;
    line-height: 1;
  }
  div.icon:hover:not(.inactive) {
    cursor: pointer;
  }
  .inactive {
    color: $primary-inactive;
  }
  .active,
  .left,
  .right {
    transition: transform 0.2s ease;
  }
  .right.active:hover {
    transform: translateX(5px);
  }
  .left.active:hover {
    transform: translateX(-5px);
  }
}

.process-section-mob {
}

.process-container-horizontal {
  height: 100%;
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-snap-stop: always;
  .process-section {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    scroll-snap-align: start;

    a {
      font-size: $title-mb-fs;
      margin-bottom: 3rem;
      &.inactive {
        color: $primary-inactive;
      }
    }
    img {
      height: 50vh;
      width: 70%;
      object-fit: contain;
    }
  }
}

.circle {
  margin: 5px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: $primary;
  &.inactive {
    background-color: $primary-inactive;
  }
}
