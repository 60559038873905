.section-title {
  color: $section-title-color;
  line-height: 1;
  text-transform: uppercase;
  &.rotated {
    position: absolute;
    font-size: 11.25rem;
    transform: rotate(90deg);
    top: 35%;
    left: -50%;
    @media only screen and (min-width: 1200px) {
      left: -33%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    @media only screen and (max-width: 576px) {
      display: block;
    }
  }
  &.mobile-v {
    font-size: 3rem;
  }
}

.heading-title {
  color: $primary-dark;
  font-weight: 700;
  font-size: $heading-title-fs;
  line-height: 1;
  @media only screen and (max-width: 1024px) {
    font-size: $heading-title-t-fs;
  }
  @media only screen and (max-width: 576px) {
    font-size: $heading-title-mb-fs;
  }
}

.title {
  color: $primary-dark;
  font-weight: 500;
  font-size: $title-fs;
  line-height: 1;
  @media only screen and (max-width: 768px) {
    font-size: $title-t-fs;
  }
  @media only screen and (max-width: 576px) {
    font-size: $title-mb-fs;
  }
}

.common-paragraph {
  color: $text-color;
  font-size: $paragraph-fs;
  margin-top: 16px;
  line-height: 1.2;
  &.dark-section {
    color: #647f9b;
  }
  @media only screen and (max-width: 768px) {
    font-size: $paragraph-t-fs;
  }
  @media only screen and (max-width: 576px) {
    font-size: $paragraph-mb-fs;
    margin-top: 10px;
  }
}
