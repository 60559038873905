.tech-desk-container {
  .section-title {
    left: -10%;
    color: #143454;
    @media only screen and (max-width: 1830px) {
      left: -15%;
    }
    @media only screen and (max-width: 1700px) {
      left: -20%;
    }
    @media only screen and (max-width: 1550px) {
      left: -25%;
    }
    @media only screen and (max-width: 1440px) {
      display: none;
    }
  }
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.panel {
  background-color: $primary-light;
  color: white;
  margin-top: -15px;
  border-radius: 10px;
  padding: 0rem 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-bottom: 2rem;
}

.tech-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1b3f62;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  z-index: 501;
  > .card-title {
    color: white;
    margin-right: 1rem;
  }
  > .circle {
    background-color: $primary-dark;
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    .icon {
      color: white;
    }
  }
}

$circle1-radius: 55%;
$circle2-radius: 70%;
$circle3-radius: 85%;
$circle4-radius: 100%;

$radious-orbit1: 200px;
$radious-orbit2: 210px;
$radious-orbit3: 220px;
$radious-orbit4: 240px;
$radious-orbit5: 250px;
$radious-orbit6: 260px;
$radious-orbit7: 280px;
$radious-orbit8: 300px;

$factor: 1.4;

.tech-section-mob {
}

.tech-animate-container {
  position: relative;
  width: 50vh;
  height: 50vh;
  background-color: transparent;
  .image-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      width: 55%;
      height: auto;
      z-index: 999;
    }
    @media only screen and (min-width: 768px) {
      img {
        width: 65%;
        height: auto;
        // height: max-content;
      }
    }
    @media only screen and (min-width: 1200px) {
      img {
        width: 100%;
        height: auto;
        // height: max-content;
      }
    }
  }
  &.mob {
    width: 100%;
    width: 80vw;
    height: 80vw;
    img {
      height: 100%;
      width: auto;
      // height: max-content;
    }
    @media only screen and (max-width: 425px) {
      img {
        width: 100%;
        height: auto;
        // height: max-content;
      }
    }
  }
  span {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    border-radius: 50%;
    border: 3px solid #204b77;
    background-color: transparent;
    animation: fadeCircle 3s ease-in-out infinite;
    &#circle-1 {
      width: $circle1-radius;
      height: $circle1-radius;
    }
    &#circle-2 {
      width: $circle2-radius;
      height: $circle2-radius;
      animation-delay: 0.125s;
    }
    &#circle-3 {
      width: $circle3-radius;
      height: $circle3-radius;
      animation-delay: 0.25s;
    }
    &#circle-4 {
      width: $circle4-radius;
      height: $circle4-radius;
      animation-delay: 0.5s;
    }
  }
  div.orbit {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    > div {
      width: 55px;
      height: 55px;
      background: cyan;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      margin: auto;
      background: linear-gradient(120.85deg, rgba(210, 225, 240, 0.15) 17.36%, rgba(112, 135, 159, 0.29) 76.82%);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      border-radius: 83px;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      img {
        position: absolute;
        width: 55%;
        height: auto;
        transform: translate(-50%, -50%);
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
      }
    }
    &#orbit-1 {
      width: $radious-orbit1;
      height: $radious-orbit1;
      #bubble1-o1 {
        animation-duration: 17s;
        animation-delay: 1s;
        animation-name: circle_o1;
        &.mob {
          animation-name: circle_o1_mob;
        }
      }
    }
    &#orbit-2 {
      width: $radious-orbit2;
      height: $radious-orbit2;
      #bubble1-o2 {
        animation-duration: 17.5s;
        animation-delay: 3s;
        animation-name: circle_o2;
        &.mob {
          animation-name: circle_o2_mob;
        }
      }
    }
    &#orbit-3 {
      width: $radious-orbit3;
      height: $radious-orbit3;
      #bubble1-o3 {
        animation-duration: 16.5s;
        animation-delay: 2s;
        animation-name: circle_o3;
        &.mob {
          animation-name: circle_o3_mob;
        }
      }
    }
    &#orbit-4 {
      width: $radious-orbit4;
      height: $radious-orbit4;
      #bubble1-o4 {
        animation-duration: 16s;
        animation-delay: 3.5s;
        animation-name: circle_o4;
        &.mob {
          animation-name: circle_o4_mob;
        }
      }
    }
    &#orbit-5 {
      width: $radious-orbit5;
      height: $radious-orbit5;
      #bubble1-o5 {
        animation-duration: 15.5s;
        animation-delay: 2.5s;
        animation-name: circle_o5;
        &.mob {
          animation-name: circle_o5_mob;
        }
      }
    }
    &#orbit-6 {
      width: $radious-orbit6;
      height: $radious-orbit6;
      #bubble1-o6 {
        animation-duration: 19s;
        animation-delay: 3s;
        animation-name: circle_o6;
        &.mob {
          animation-name: circle_o6_mob;
        }
      }
    }
    &#orbit-7 {
      width: $radious-orbit7;
      height: $radious-orbit7;
      #bubble1-o7 {
        animation-duration: 20s;
        animation-delay: 1s;
        animation-name: circle_o7;
        &.mob {
          animation-name: circle_o7_mob;
        }
      }
    }
    &#orbit-8 {
      width: $radious-orbit8;
      height: $radious-orbit8;
      #bubble1-o8 {
        animation-duration: 18s;
        animation-delay: 1.5s;
        animation-name: circle_o8;
        &.mob {
          animation-name: circle_o8_mob;
        }
      }
    }
  }
}

@keyframes pulseImg {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  20% {
    transform: translate(-50%, -50%) scale(1.02);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeCircle {
  0% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.05);
  }
  100% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes circle_o1 {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit1})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit1})) rotate(180deg);
  }
}
@keyframes circle_o1_mob {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit1} / #{$factor})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit1} / #{$factor})) rotate(180deg);
  }
}

@keyframes circle_o2 {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit2})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit2})) rotate(-360deg);
  }
}
@keyframes circle_o2_mob {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit2} / #{$factor})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit2} / #{$factor})) rotate(-360deg);
  }
}

@keyframes circle_o3 {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit3})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit3})) rotate(180deg);
  }
}
@keyframes circle_o3_mob {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit3} / #{$factor})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit3} / #{$factor})) rotate(180deg);
  }
}

@keyframes circle_o4 {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit4})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit4})) rotate(-360deg);
  }
}
@keyframes circle_o4_mob {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit4} / #{$factor})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit4} / #{$factor})) rotate(-360deg);
  }
}

@keyframes circle_o5 {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit5})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit5})) rotate(-360deg);
  }
}
@keyframes circle_o5_mob {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit5} / #{$factor})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit5} / #{$factor})) rotate(-360deg);
  }
}

@keyframes circle_o6 {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit6})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit6})) rotate(180deg);
  }
}
@keyframes circle_o6_mob {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit6} / #{$factor})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit6} / #{$factor})) rotate(180deg);
  }
}

@keyframes circle_o7 {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit7})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit7})) rotate(-360deg);
  }
}
@keyframes circle_o7_mob {
  0% {
    transform: rotate(0deg) translate(calc(-#{$radious-orbit7} / #{$factor})) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(calc(-#{$radious-orbit7} / #{$factor})) rotate(-360deg);
  }
}

@keyframes circle_o8 {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit8})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit8})) rotate(180deg);
  }
}
@keyframes circle_o8_mob {
  0% {
    transform: rotate(180deg) translate(calc(-#{$radious-orbit8} / #{$factor})) rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg) translate(calc(-#{$radious-orbit8} / #{$factor})) rotate(180deg);
  }
}
