.heading-section {
  // margin: 100px 0px;
  height: 100%;
  margin-top: 50px;
  img {
    position: absolute;
    height: auto;
    top: 50%;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    &#img1 {
      width: 280px;
      z-index: 100;
      left: 75%;
      animation: img1_animation 1.5s forwards;
      animation-delay: 0.5s;
    }
    &#img2 {
      width: 250px;
      left: 75%;
      z-index: 99;
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
      animation: img2_animation 1.5s forwards;
      animation-delay: 0.5s;
    }
    &#img3 {
      width: 220px;
      z-index: 98;
      left: 75%;
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
      animation: img3_animation 1.5s forwards;
      animation-delay: 0.5s;
    }
  }
}

@keyframes img1_animation {
  from {
    left: 75%;
  }
  to {
    left: 50%;
    transform: translate(-70%, -50%) scale(101%);
  }
}
@keyframes img2_animation {
  from {
    left: 75%;
  }
  to {
    transform: perspective(1500px) rotateX(-10deg) rotateY(-10deg) translate(-45%, -50%);
    -webkit-transform: perspective(1500px) rotateY(-10deg) translate(-40%, -50%);
  }
}
@keyframes img3_animation {
  from {
    left: 75%;
  }
  to {
    left: 100%;
    top: 50%;
    transform: perspective(1500px) rotateX(-10deg) rotateY(-20deg) translate(-35%, -50%);
    -webkit-transform: perspective(1500px) rotateY(-20deg) translate(-25%, -50%);
  }
}

.heading-section-mob {
  margin-top: 50px;
  position: relative;
  .button {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    width: max-content;
    transform: translate(-50%, 50%);
    background-color: $primary;
    color: white;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 100;
  }
  .title-container {
    z-index: 2;
  }
  div.img-container {
    position: relative;
    // background-color: red;
    margin-top: -30px;
    z-index: 1;
    height: 50vh;
    width: 100%;
  }
  img {
    position: absolute;
    height: auto;
    // top: 0;
    left: 50%;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    &#img1 {
      width: 180px;
      z-index: 100;
      animation: img1_animation_mob 1.5s forwards;
      animation-delay: 0.5s;
    }
    &#img2 {
      width: 160px;
      z-index: 99;
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
      animation: img2_animation_mob 1.5s forwards;
      animation-delay: 0.5s;
    }
    &#img3 {
      width: 140px;
      z-index: 98;
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
      animation: img3_animation_mob 1.5s forwards;
      animation-delay: 0.5s;
    }
  }
}

$rotation-angle: -30deg;

@keyframes img1_animation_mob {
  from {
  }
  to {
    left: 45%;
    bottom: 30%;
    transform: translate(-50%, 50%) rotate(#{$rotation-angle});
  }
}
@keyframes img2_animation_mob {
  from {
  }
  to {
    left: 60%;
    bottom: 45%;
    transform: translate(-50%, 50%) rotate(calc(#{$rotation-angle} + 15deg));
  }
}
@keyframes img3_animation_mob {
  from {
  }
  to {
    left: 75%;
    bottom: 60%;
    transform: translate(-50%, 50%) rotate(calc(#{$rotation-angle} + 20deg));
  }
}
