.portfolio-title {
  font-size: 11.25rem;
  color: $section-title-color;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 30px;
  @media only screen and (max-width: 1024px) {
    font-size: 8rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 4rem;
  }
}
.portfolio-divider {
  position: absolute;
  top: 70px;
  right: -203px;
  transform: scale(0.9);
  @media only screen and (max-width: 1024px) {
    top: 30px;
    transform: scale(0.7);
  }
  @media only screen and (max-width: 768px) {
    top: -28px;
    transform: scale(0.6);
  }
}
.portfolio-row {
  height: 70vh;
  width: 100vw;
  &.mob {
    height: auto;
  }
  .image-row {
    max-width: 50vw;
    background-color: $primary;
    &.mob {
      max-width: 100vw;
      height: auto;
    }
    .pattern {
      position: absolute;
      width: 75%;
      height: auto;
      z-index: 0;
    }
    img {
      max-width: 90%;
      z-index: 1;
    }
  }
  .detail-row {
    max-width: 50vw;
    background-color: $primary-dark;
    &.mob {
      max-width: 100vw;
    }
    > div {
      max-width: 75%;
    }
    .title,
    .description,
    .description-t {
      color: white !important;
    }
    .title {
      text-transform: uppercase;
    }
    .description-t {
      font-size: 13px;
      margin-top: 1rem;
      text-transform: uppercase;
    }
    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #15426c;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      .icon {
        color: $primary;
        font-size: 0.8rem;
      }
    }
    .item {
      color: $primary;
      font-weight: 500;
      line-height: 1;
    }
  }
}
