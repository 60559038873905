.contact-container {
  height: 50vh;
  width: 100vw;
  &.mob {
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .contact {
    background-color: #eaf3f9;
    max-width: 50vw;
    &.mob {
      min-width: 100vw;
      padding: 3rem 0rem;
    }
    > div {
      max-width: 75%;
    }
  }
  .sec-title {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #15426c;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    .icon {
      color: $primary;
      font-size: 0.9rem;
    }
  }
  .data {
    color: $primary;
    font-size: $paragraph-fs;
    line-height: 1;
  }
  .map {
    min-height: 50vh;
    height: 50vh;
  }
}

.footer-container {
  color: white;
  background-color: $primary-dark;
  padding: 3rem 0rem;
  width: 100vw;
  .icon {
    color: $primary;
    font-size: 1rem;
  }
  .widget-title {
    font-size: 14px;
  }
  .widget-data {
    font-size: 13px;
    &.menu {
      text-transform: uppercase;
      margin: 0.2rem 0;
    }
  }
  .website-name {
    font-size: $title-fs;
    line-height: 1;
  }
  .website-caption {
    font-size: $paragraph-fs;
  }
}

.copyright-section {
  background-color: #274d73;
  color: #c2c2c2;
  font-size: 12px;
  width: 100vw;
}
