// COLORS
$primary: #2e84d3;
$primary-inactive: #bae3ff;
$primary-dark: #0a2540;
$primary-light: #2f577f;
$text-color: #575757;
$inactive-text-color: #808e9c;
$section-title-color: #e5e8ea;

// FONT SIZE
// desk
$base-fs: 1rem;
$heading-title-fs: 3.2rem;
$title-fs: 2.25rem;
$paragraph-fs: 1.3rem;
$button-fs: 1.125rem;
// tablet
$heading-title-t-fs: 4em;
$title-t-fs: 2rem;
$paragraph-t-fs: 1.125rem;
// mobile
$heading-title-mb-fs: 3rem;
$title-mb-fs: 1.5rem;
$paragraph-mb-fs: 1.125rem;
