.integration-img-desk {
  position: absolute;
  top: -462px;
  right: -321px;
  transform: rotate(-15deg) scale(0.8);
  z-index: -1;
  @media only screen and (max-width: 1024px) {
    top: -481px;
    right: -321px;
    transform: scale(0.8) rotate(-15deg);
  }
  @media only screen and (max-width: 768px) {
    top: -767px;
    right: -420px;
    transform: rotate(-15deg) scale(0.5);
  }
}

.section-int-mob {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 80vh;
  margin-top: -40px;
  margin-bottom: -40px;
  // background-color: red;
  .title {
    background: #0094ff;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 20px 40px -12px rgba($color: #0094ff, $alpha: 0.35);
  }
  .common-paragraph {
    background: #69e9d2;
    color: #1f3248;
    text-align: center;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0px 20px 40px -12px rgba($color: #69e9d2, $alpha: 0.35);
  }
  .integration-img-mob {
    height: 120vh;
    width: 100%;
    text-align: center;
    padding-top: 100px;
    color: white;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .integration-img-mob::before {
    content: "";
    position: absolute;
    width: 140vw;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../images/integration_mob.png");
    background-size: cover;
    transform: translate(-50%, -50%) rotate(9deg);
  }
}
